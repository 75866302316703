import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { device } from "./MediaQuery"
import useTimeout from "@rooks/use-timeout"

const Events = () => {
  const { start, clear } = useTimeout(animateIn, 1000)
  const [isAnimatedIn, setIsAnimatedIn] = useState(false)

  function animateIn() {
    setIsAnimatedIn(true)
  }

  useEffect(() => {
    start()
    return clear
  }, [start, clear])

  return (
    <Container isAnimatedIn={isAnimatedIn}>
      {/* <div>
        <h1>Come See Us Live</h1>
        <ul>
           <li>
            <a
              href="https://www.facebook.com/events/850591222322163?acontext=%7B%22event_action_history%22%3A[%7B%22surface%22%3A%22page%22%7D]%7D"
              target="_blank"
              rel="noreferrer"
            >
              <strong>[SUN 24 APRIL 2022]</strong>
              <span>JH ASGAARD GENTBRUGGE</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/events/317881390237235"
              target="_blank"
              rel="noreferrer"
            >
              <strong>[FRI 29 APRIL 2022 20:00]</strong>
              <span>PEKTON MECHELEN</span>
            </a>
          </li>
        </ul>
      </div> */}
    </Container>
  )
}

const Container = styled.div`
  top: 10rem;
  opacity: ${props => (props.isAnimatedIn ? 1 : 0)};
  position: relative;
  transition: opacity 1s cubic-bezier(0.16, 1, 0.3, 1);
  text-decoration: none;
  color: #fff;

  h1 {
    font-size: 1.5rem;
    font-family: "grindanddeath";

    @media ${device.landscape} {
      font-size: 2rem;
    }
  }

  ul {
    font-size: 1.325rem;

    a {
      color: #fff;
      text-decoration: none;

      strong,
      span {
        display: block;

        @media ${device.landscape} {
          display: inline;
        }
      }

      strong {
        margin-right: 0.75rem;
      }

      span {
        font-family: "grindanddeath";

        @media ${device.landscape} {
          font-size: 2rem;
          position: relative;
          top: 3px;
        }
      }
    }
  }
`

export default Events
