import React, { useEffect, useState } from "react"
import styled from "styled-components"
import useTimeout from "@rooks/use-timeout"
import { device } from "./MediaQuery"
const SocialMedias = () => {
  const { start, clear } = useTimeout(animateIn, 700)
  const [isAnimatedIn, setIsAnimatedIn] = useState(false)

  function animateIn() {
    setIsAnimatedIn(true)
  }

  useEffect(() => {
    start()
    return clear
  }, [start, clear])

  return (
    <MediasStyled isAnimatedIn={isAnimatedIn}>
      <Link
        href="https://www.instagram.com/short.fuse.band/"
        target="_blank"
        delay={"0.3s"}
      >
        <img src={`/images/svg/instagram.svg`} alt="Instagram" />
        <span>Follow Us</span>
      </Link>

      <Link
        href="https://www.facebook.com/shortfuseband"
        target="_blank"
        delay={"0.5s"}
      >
        <img src={`/images/svg/facebook.svg`} alt="Facebook" />
        <span>Like Us</span>
      </Link>

      <Link
        href="https://soundcloud.com/shortfusehardrock"
        target="_blank"
        delay={"0.7s"}
      >
        <img src={`/images/svg/soundcloud.svg`} alt="Soundcloud" />
        <span>Listen to Us</span>
      </Link>

      <Link href="mailto:short.fuse.hardrock@gmail.com" delay={"0.9s"}>
        <img src={`/images/svg/envelope.svg`} alt="Mail" />
        <span>Book Us</span>
      </Link>
    </MediasStyled>
  )
}

const MediasStyled = styled.div`
  top: 5rem;
  position: relative;
  transition: opacity 1s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: ${props => (props.isAnimatedIn ? 1 : 0)};
  width: 100%;
  top: 2rem;

  @media ${device.portrait} {
    display: flex;
    top: 5rem;
    justify-content: space-between;
    width: 750px;
    left: -10px;
  }

  @media ${device.landscape} {
    width: 800px;
    left: 0;
  }

  @media ${device.laptop} {
    width: 1000px;
  }
`

const Link = styled.a`
  font-family: "grindanddeath";
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  opacity: 0.8;
  transition: opacity 0.7s cubic-bezier(0.16, 1, 0.3, 1);
  margin-bottom: 0.75rem;

  @media ${device.portrait} {
    margin-bottom: 0;
  }

  @media (hover: none) {
    opacity: 1;
  }

  &:hover {
    opacity: 1;

    img {
      transform: scale(1.2);
    }

    span {
      transform: translate(5px, 0);
    }
  }

  img {
    transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);
    color: #fff;
    transform: scale(1);
    width: 32px;
    margin: 0 1.125rem 0;

    &.no-m {
      margin: 0 1rem 0 0;
    }
  }

  span {
    font-family: "grindanddeath";
    font-size: 1.5rem;
    transition: transform 0.7s cubic-bezier(0.16, 1, 0.3, 1);
    transform: translate(0, 0);
  }
`

export default SocialMedias
