import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Events from "./Events"
import { device } from "./MediaQuery"
import SocialMedias from "./SocialMedias"

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()

  const onLoad = () => {
    setLoaded(true)
  }

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad()
    }
  })

  return [ref, loaded, onLoad]
}

const Logo = () => {
  const [leatherRef, leatherLoaded, onLoadLeather] = useImageLoaded()
  const [logoRef, logoLoaded, onLoadLogo] = useImageLoaded()

  return (
    <LogoStyled leatherLoaded={leatherLoaded} logoLoaded={logoLoaded}>
      <img
        ref={leatherRef}
        onLoad={onLoadLeather}
        className="leather"
        src={`/images/leather.jpg`}
        alt="Background"
      />
      <img
        ref={logoRef}
        onLoad={onLoadLogo}
        className="logo"
        src={`/images/short-fuse-big.png`}
        alt="Short Fuse"
      />
      {leatherLoaded && logoLoaded && <SocialMedias />}
      {leatherLoaded && logoLoaded && <Events />}
    </LogoStyled>
  )
}

const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;

  .leather {
    position: absolute;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
    opacity: ${props => (props.leatherLoaded ? 1 : 0)};
    left: 50%;
    transform: translate(-50%, -50%);
    top: 40%;
    width: 150%;

    @media ${device.portrait} {
      top: 50%;
    }

    @media ${device.phone} {
      width: 100%;
    }

    @media ${device.landscape} {
      width: 75%;
    }

    @media ${device.laptop} {
      width: 60%;
    }
  }

  .logo {
    position: relative;
    transition: opacity 1s cubic-bezier(0.16, 1, 0.3, 1) 0.4s;
    opacity: ${props => (props.logoLoaded ? 1 : 0)};
    height: auto;
    width: 120%;

    @media ${device.phone} {
      width: 105%;
    }

    @media ${device.landscape} {
      width: 75%;
    }

    @media ${device.laptop} {
      width: 50%;
    }
  }
`

export default Logo
