import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body, 
  html, 
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }
  
  html,
  body {
    overflow-x: hidden;
  }

  body {
    margin: 0;
    background-color: #000;
  }
 
  @font-face {
    font-family: 'grindanddeath';
    src: url('/fonts/grindanddeath.woff2') format('woff2'),
         url('/fonts/grindanddeath.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`
export default function Layout({ children }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      {children}
    </React.Fragment>
  )
}
